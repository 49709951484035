import * as typography from './typography';
import breakpoints from './breakpoints';
import colors from './colors';
import { ThemeOptions } from '@mui/material';
import { FontFamilyEnum } from './typography';

export const fontFamily = ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const theme = {
  breakpoints,
  colors,
  typography,
};

export const brandedMuiTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  // @ts-ignore
  typography: {
    fontSize: 22,
    fontFamily,
    heading1: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '4.8rem',
      fontWeight: 700,
      lineHeight: '4.8rem',
      letterSpacing: '-0.02em',
      textAlign: 'left',
    },
    heading2: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '4rem',
      fontWeight: 700,
      lineHeight: '4.4rem',
      letterSpacing: '-0.02em',
      textAlign: 'left',
    },
    heading3: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '3.2rem',
      fontWeight: 700,
      lineHeight: '3.6rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading4: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '2.8rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading5: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    boldedParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: '700',
      lineHeight: '2.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    heading6: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    introParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2.8rem',
      letterSpacing: '-0.01em',
      textAlign: 'left',
    },
    regularParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '2.2rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    smallParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '2rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    extraSmallParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: '1.6rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    tinyParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    legalParagraph: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.4rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    smallTableHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },
    tableHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    boldedTableHeader: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
    },
    widgetTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
    },
    componentHeaderDashboardTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    componentHeaderWidgetTitle: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },
    componentHeaderDate: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    subheadingSmall: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '1.6rem',
    },
    tableRowHeadline: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
    },
    smallTableTotals: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    tableTotals: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
    },
    tableHeaderDetails: {
      fontFamily: FontFamilyEnum.OPEN_SANS,
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: '1.92rem',
      letterSpacing: '0.04rem',
    },
  },
  palette: {
    primary: {
      main: colors.amethystMain,
      extraLight: colors.amethystExtraLight,
      light: colors.amethystLight,
      dark: colors.amethystDark,
      extraDark: colors.amethystExtraDark,
    },
    secondary: {
      main: colors.midnightMain,
      extraLight: colors.midnightExtraLight,
      light: colors.midnightLight,
      dark: colors.midnightDark,
      extraDark: colors.midnightExtraDark,
    },
    error: {
      main: colors.errorMain,
      extraLight: colors.errorExtraLight,
      light: colors.errorLight,
      dark: colors.errorDark,
      extraDark: colors.errorExtraDark,
    },
    warning: {
      main: colors.warningMain,
      extraLight: colors.warningExtraLight,
      light: colors.warningLight,
      dark: colors.warningDark,
      extraDark: colors.warningExtraDark,
    },
    info: {
      main: colors.periwinkleMain,
      extraLight: colors.periwinkleExtraLight,
      light: colors.periwinkleLight,
      dark: colors.periwinkleDark,
      extraDark: colors.periwinkleExtraDark,
    },
    success: {
      main: colors.mintMain,
      extraLight: colors.mintExtraLight,
      light: colors.mintLight,
      dark: colors.mintDark,
      extraDark: colors.mintExtraDark,
    },
    disabled: {
      main: colors.disabled,
    },
    backgroundGrey: {
      main: colors.backgroundGrey,
    },
    cloudGrey: {
      main: colors.cloudGrey,
    },
    white: {
      main: colors.white,
    },
    black: {
      main: colors.black,
    },
    blue: {
      main: colors.chartPrimaryRoyalBlue,
      light: colors.royalBlueExtraLight,
    },
    amethyst: {
      main: colors.amethystMain,
      extraLight: colors.amethystExtraLightBg,
    },
  },
};

export type Theme = typeof theme;

export default theme;
