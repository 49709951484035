/*
Author: Eli Elad Elrom
*/

import { CacheProvider, EmotionCache } from '@emotion/react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import { AuthCheck } from 'components/routing/components/AuthCheck';
import { GlobalScrollbar } from 'mac-scrollbar';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { EnvironmentKey, RecoilRelayEnvironmentProvider } from 'recoil-relay';
import { ThemeProvider } from 'styled-components';
import SiteHeader from '../src/components/layout/SiteHeader';
import Toaster from '../src/components/shared/Toaster/Toaster';
import { ToasterWithoutState } from 'components/shared/Toaster/ToasterWithoutState';
import { NextPublicEnvironmentEnum } from 'enums/nextPublicEnvironmentEnum';
import environment from '../src/recoil/graphql/createRelayEnvironment';
import GlobalStyle from '../src/styles/GlobalStyle';
import theme, { brandedMuiTheme } from '../src/styles/theme';
import createEmotionCache from '../src/utils/createEmotionCache';
import { UserClientPermissionCheck } from 'components/routing/components/UserClientPermissionCheck';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { useOktaSDKStatus } from '../src/hooks/useOktaSDKStatus';

LicenseInfo.setLicenseKey(`${process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY}`);
const clientSideEmotionCache = createEmotionCache();

export const myEnvironmentKey = new EnvironmentKey(
  process.env.NEXT_PUBLIC_SERVER_ENVIRONMENT_TYPE as string
);

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
  const muiTheme = createTheme(brandedMuiTheme);
  const PageComponent = Component as any;

  const isOktaSDKLoadComplete = useOktaSDKStatus();

  if (isOktaSDKLoadComplete) {
    return (
      <CacheProvider value={emotionCache}>
        <Head>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="HandheldFriendly" content="True" />
          <meta name="MobileOptimized" content="320" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <script src="https://widget.cloudinary.com/v2.0/global/all.js" async />
          <title>Portfolio Analytics</title>
        </Head>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <Toaster>
              <ToasterWithoutState />
              <RecoilRoot>
                <RecoilRelayEnvironmentProvider
                  environment={environment}
                  environmentKey={myEnvironmentKey}
                >
                  <RecoilNexus />
                  <AuthCheck>
                    <UserClientPermissionCheck>
                      <GlobalStyle />
                      <SiteHeader />
                      <GlobalScrollbar />
                      <PageComponent {...pageProps} />
                    </UserClientPermissionCheck>
                  </AuthCheck>
                </RecoilRelayEnvironmentProvider>
              </RecoilRoot>
            </Toaster>
          </MuiThemeProvider>
        </ThemeProvider>
      </CacheProvider>
    );
  } else {
    // Server rendering in progress
    return <></>;
  }
}

export default process.env.NEXT_PUBLIC_ENVIRONMENT !== NextPublicEnvironmentEnum.PASSWORD
  ? MyApp
  : withPasswordProtect(MyApp, {
      // @ts-ignore
      loginApiPath: ROUTES_PATH_URLS.account_sign_in,
      cookieName: 'next-password-protect',
    });

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
