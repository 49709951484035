import LightGreyIcon from '../LightGreyIcon';
import { IconButtonProps, Tooltip, Divider } from '@mui/material';
import { IconButton } from '@ay/pascl';

const HeaderIconButton = (props: HeaderIconButtonProps) => {
  const { icon, tooltipText, divider = false, id, ...rest } = props;
  return (
    <>
      <Tooltip title={tooltipText || ''} arrow enterDelay={1000}>
        <div>
          <IconButton id={id} {...rest}>
            <LightGreyIcon>{icon}</LightGreyIcon>
          </IconButton>
        </div>
      </Tooltip>
      {divider && <Divider orientation="vertical" variant="middle" flexItem />}
    </>
  );
};

HeaderIconButton.defaultProps = {};

export interface HeaderIconButtonProps extends IconButtonProps {
  icon: React.ReactNode;
  tooltipText?: string;
  divider?: boolean;
  id?: string;
}

export default HeaderIconButton;
