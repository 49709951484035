import Cookies from 'js-cookie';

export const addDataAsCookie = (data: any, cookieName: string, expirationHours: number) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + expirationHours * 60 * 60 * 1000);
  const tokenString = JSON.stringify(data);
  Cookies.set(cookieName, tokenString, {
    expires: expirationDate,
  });
};

export const getDataFromCookie = (cookieName: string): any | null => {
  const data = Cookies.get(cookieName);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

export const deleteCookie = (cookieName: string) => {
  Cookies.remove(cookieName);
};

export const deleteAllCookies = () => {
  const cookies = Object.keys(Cookies.get());
  cookies.forEach((cookie) => {
    Cookies.remove(cookie);
  });
};
