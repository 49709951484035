import { useState, useEffect } from 'react';
import oktaAuthentication from '../utils/auth/oktaAuthentication';

export const useOktaSDKStatus = () => {
  const [oktaSDKLoadComplete, setOktaSDKLoadComplete] = useState(true);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervals = [
      { duration: 200, maxTries: 2 },
      { duration: 100, maxTries: 1 },
    ];

    const tokens = oktaAuthentication?.auth.tokenManager.getTokensSync();
    const isOktaAPIReady = tokens ? true : false;

    const interval = setInterval(() => {
      setOktaSDKLoadComplete(isOktaAPIReady);
      setCounter((prevCounter) => prevCounter + 1);
    }, intervals[counter]?.duration || 0);

    if (counter >= intervals.length || oktaSDKLoadComplete) {
      // Fail safe - after attempts return true anyway so app starts
      setOktaSDKLoadComplete(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  return oktaSDKLoadComplete;
};
