import Link from 'next/link';
import { Icon } from '@ay/pascl';
import { APP_CONFIG } from '../../../constants/config';

const HomePageLink = () => (
  <Link href="/">
    <a>
      <Icon
        height={27}
        width={27}
        alt="Dashboards"
        iconPath={`${APP_CONFIG.basePath}/icons/dashboard-outlined-custom.svg`}
      />
    </a>
  </Link>
);

export default HomePageLink;
