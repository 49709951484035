import { css } from 'styled-components';
import { APP_CONFIG } from '../constants/config';

const GeoSlab = css`
  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 200;
    src: url('${APP_CONFIG.basePath}/fonts/GeoSlab703/tt0310m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 300;
    src: url('${APP_CONFIG.basePath}/fonts/GeoSlab703/tt0310m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 400;
    src: url('${APP_CONFIG.basePath}/fonts/GeoSlab703/tt0311m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 500;
    src: url('${APP_CONFIG.basePath}/fonts/GeoSlab703/tt0311m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703BT';
    src: url('${APP_CONFIG.basePath}/fonts/GeoSlab703BT/GeometricSlab703BT-Medium.ttf')
      format('truetype');
  }
`;

export default GeoSlab;
