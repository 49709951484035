import { getRecoil } from 'recoil-nexus';
import { HttpMethod } from '../../src/enums/httpMethodEnum';
import { handleErrors } from './errorHandling';
import { IErrorType } from '../../src/interfaces';
import { userModelState } from '../../src/recoil/atoms/userAtoms';
import {
  CURRENT_SERVER_ENVIRONMENT_TYPE,
  EnvironmentTypeEnum,
} from 'recoil/selectors/SelectorsHelper';

const setHeaders = (body?: any, token?: string) => {
  const requestHeaders: HeadersInit = new Headers();

  if (token) {
    requestHeaders.set('authorization', `Bearer ${token}`);
  }

  const userModel = getRecoil(userModelState);
  if (userModel?.accessToken) {
    requestHeaders.set('authorization', `Bearer ${userModel.accessToken}`);
    requestHeaders.set('clientid', String(userModel.selectedUserClientModel?.clientModel.clientId));
  }

  if (body) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  return requestHeaders;
};

export function request<T>(
  url: string,
  method: HttpMethod,
  body?: any,
  token?: string
): Promise<T> {
  const requestHeaders = setHeaders(body, token);
  const isOffline = CURRENT_SERVER_ENVIRONMENT_TYPE === EnvironmentTypeEnum.OFFLINE ? true : false;
  const reqMethod = isOffline ? HttpMethod.GET : method;
  const reqBody = isOffline ? undefined : JSON.stringify(body);
  return new Promise((resolve, reject) =>
    fetch(url, {
      headers: requestHeaders,
      method: reqMethod,
      body: reqBody,
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.ok) {
          resolve(result.data);
          return;
        }
        const responseError = {
          type: 'Error',
          message: result.message || 'Something went wrong',
          error: result.error,
          data: result.data || '',
          code: result.statusCode || '',
        };
        let error = new Error();
        error = { ...error, ...responseError };
        throw error;
      })
      .catch((e) => {
        const err = e as IErrorType;
        handleErrors(err);
        reject(err);
      })
  );
}
