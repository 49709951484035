/*
Author: Eli Elad Elrom
Component: src/utils/Formatters.ts

Usage example:
  const date = getFormatter('formatDateDayMonthYear')(new Date().toISOString()).toUpperCase();

*/

import { differenceInDays, differenceInMonths, parseISO } from 'date-fns';
import format from 'date-fns/format';
import { getRecoil } from 'recoil-nexus';
import { userModelState } from '../recoil/atoms/userAtoms';
import { regex } from '../constants/regex';

const numberIsNotValid = '-';

export enum FormatterType {
  FORMAT_DATE_DAY_MONTH_YEAR = 'formatDateDayMonthYear',
  FORMAT_DATE_DAY_SHORT_MONTH_YEAR = 'formatDateDayShortMonthYear',
  FORMAT_DATE_REMAIN_YEAR_MONTH = 'formatDateRemainYearMonth',
  FORMAT_DATE_MONTH_DAY_YEAR_SLASH = 'formatDateMonthDayYearSlash',
  SQF_NUMBER_WITH_COMMAS = 'sqfNumberWithCommas',
  SQF_NUMBER_WITH_UPPER_CASE = 'sqfNumberWithUpperCase',
  ADD_CURRENCY_SYMBOL_SIGN = 'addCurrencySymbolSign',
  ADD_CURRENCY_SYMBOL_WITH_TWO_DECIMAL = 'addCurrencySymbolWithTwoDecimal',
  ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE = 'addCurrencySymbolWithCommasAndScale',
  CURRENCY_SYMBOL_WITH_COMMAS = 'currencySymbolWithCommas',
  NUMBER_WITH_COMMAS = 'numberWithCommas',
  INTEGER_WITH_COMMAS = 'integerWithCommas',
  ADD_PERCENTAGE = 'addPercentage',
  FLOAT_ONE_DECIMAL = 'floatOneDecimal',
  FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE = 'floatFourDecimalWithPercentage',
  FLOAT_ONE_DECIMAL_WITH_PERCENTAGE = 'floatOneDecimalWithPercentage',
  FLOAT_WITH_NO_DECIMAL = 'floatWithNoDecimal',
  CONVERT_FLOAT_TO_PERCENTAGE = 'convertFloatToPercentage',
  CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL = 'convertFloatToNumberWithCommasWithNoDecimal',
  CAPITALIZE_EACH_WORD = 'capitalizeEachWord',
  TRIM_STRING_WITH_SPACES = 'trimStringWithSpaces',
  TRIM_LONG_TEXT = 'trimLongText',
  FORMAT_NUMBER_AS_KILO_MILLION = 'formatNumberAsKiloMillion',
}

interface CurrencyMap {
  [key: string]: string;
}

const currencySymbolMap: CurrencyMap = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'CHF',
  CNY: '¥',
  HKD: 'HK$',
  NZD: 'NZ$',
  SEK: 'kr',
  KRW: '₩',
  SGD: 'S$',
  NOK: 'kr',
  MXN: 'Mex$',
  INR: '₹',
  RUB: '₽',
  ZAR: 'R',
  TRY: '₺',
  BRL: 'R$',
  TWD: 'NT$',
  DKK: 'kr',
};

export const parseDate = (date: string, formatStr: string) => {
  let val;
  try {
    val = format(parseISO(date), formatStr);
    return val;
  } catch (error) {
    return date;
  }
};

const isValidNumberFormatter = (number: string | number) => {
  return !isNaN(parseInt(String(number)));
};
export const getFormatter = (functionName: string) => {
  const userModel = getRecoil(userModelState);
  let currencyCode = '$';
  let currencySymbol = '$';
  if (userModel) {
    currencyCode = userModel?.selectedUserClientModel?.currencyCode || '$';
    currencySymbol = currencySymbolMap[currencyCode] || '$';
  }
  let retVal = (num: any) => num;
  switch (functionName) {
    case FormatterType.FORMAT_DATE_DAY_MONTH_YEAR:
      retVal = (date: string) => parseDate(date, 'dd-MMM-yyyy');
      break;
    case FormatterType.FORMAT_DATE_DAY_SHORT_MONTH_YEAR:
      retVal = (date: string) => parseDate(date, 'dd-LLL-yyyy');
      break;
    case FormatterType.FORMAT_DATE_REMAIN_YEAR_MONTH:
      retVal = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();
        const monthsSinceDate = differenceInMonths(today, date);
        const daysSinceDate = differenceInDays(today, date);
        if (monthsSinceDate < 0) {
          return 'Expired';
        }
        const numYears = Math.floor(monthsSinceDate / 12);
        const numMonths = monthsSinceDate - numYears * 12;
        if (!numYears && !numMonths) {
          return `${daysSinceDate} Days`;
        }
        if (!numYears) {
          return `${numMonths} Mos.`;
        }
        return `${numYears} Yr, ${numMonths} Mos.`;
      };
      break;
    case FormatterType.FORMAT_DATE_MONTH_DAY_YEAR_SLASH:
      retVal = (date: string) => parseDate(date, 'MM/dd/yyyy');
      break;
    case FormatterType.SQF_NUMBER_WITH_COMMAS:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return `${parseInt(num, 10).toLocaleString()}`;
      };
      break;
    case FormatterType.SQF_NUMBER_WITH_UPPER_CASE:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return `${parseInt(num, 10).toLocaleString()} SQF`;
      };
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_SIGN:
      retVal = (num: number) => {
        if (!isValidNumberFormatter(num)) {
          return numberIsNotValid;
        }
        return `${currencySymbol}${Number(num).toFixed(2)}`;
      };
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_TWO_DECIMAL:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return `${currencySymbol}${Number(num as unknown as number).toFixed(2)}`;
      };
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        const parsedNum = parseInt(num, 10);
        if (parsedNum >= 1000000000) {
          return `${currencySymbol}${Math.floor(parsedNum / 1000000000).toLocaleString()} M`;
        } else if (parsedNum >= 1000000) {
          return `${currencySymbol}${Math.floor(parsedNum / 1000).toLocaleString()} K`;
        } else {
          return `${currencySymbol}${parsedNum.toLocaleString()}`;
        }
      };
      break;
    case 'addCurrencySymbolWithCommasBasedOnUserCurrency':
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) {
          return numberIsNotValid;
        }
        const formattedNum = parseInt(num, 10).toLocaleString();
        return parseInt(num, 10) >= 0 ? `${currencySymbol}${formattedNum}` : `-${formattedNum}`;
      };
      break;
    case FormatterType.CURRENCY_SYMBOL_WITH_COMMAS:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        if (parseFloat(num) > 0) {
          return `${currencySymbol}${parseFloat(num).toFixed(2).toLocaleString()}`;
        } else {
          return `-${currencySymbol}${(parseFloat(num) * -1).toFixed(2).toLocaleString()}`;
        }
      };
      break;
    case FormatterType.NUMBER_WITH_COMMAS:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return num.toString().replace(regex.numberWithCommas, ',');
      };
      break;
    case FormatterType.INTEGER_WITH_COMMAS:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return parseInt(num).toString().replace(regex.numberWithCommas, ',');
      };
      break;
    case FormatterType.ADD_PERCENTAGE:
      retVal = (value: string | number) => value + '%';
      break;
    case FormatterType.FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return (num as unknown as number).toFixed(4) + '%';
      };
      break;
    case FormatterType.FLOAT_ONE_DECIMAL:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return (num as unknown as number).toFixed(1);
      };
      break;
    case FormatterType.FLOAT_ONE_DECIMAL_WITH_PERCENTAGE:
      retVal = (num: string | number) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
        return parsedNum.toFixed(1) + '%';
      };
      break;
    case FormatterType.FLOAT_WITH_NO_DECIMAL:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return Math.round(num as unknown as number);
      };
      break;
    case FormatterType.CONVERT_FLOAT_TO_PERCENTAGE:
      retVal = (num: string | number) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return (Number(num) * 100).toFixed(2) + '%';
      };
      break;
    case FormatterType.CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL:
      retVal = (num: number) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return num.toFixed(0).toString().replace(regex.numberWithCommas, ',');
      };
      break;
    case FormatterType.CAPITALIZE_EACH_WORD:
      retVal = (str: string) => {
        return str
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
      break;
    case FormatterType.TRIM_STRING_WITH_SPACES:
      retVal = (str: string) => {
        const strSplit = str.split('|');
        const text: string = strSplit[0];
        const maxLength: number = parseInt(strSplit[1]);
        if (text.length <= maxLength) {
          return text;
        }

        const trimmedText = text.substr(0, maxLength).trim();
        return `${trimmedText}...`;
      };
      break;
    case FormatterType.FORMAT_NUMBER_AS_KILO_MILLION:
      retVal = (num: number): string =>
        Intl.NumberFormat('en', { notation: 'compact' }).format(num);
      break;
  }
  return retVal;
};
