/*
Author: Eli Elad Elrom
*/

import { createGlobalStyle } from 'styled-components';

import OpenSans from './OpenSans';
import colors from './colors';
import { FontFamilyEnum } from './typography';
import GeoSlab from './GeoSlab';
import 'mac-scrollbar/dist/mac-scrollbar.css';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-size: 62.5%;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
  
  {/* ---- ScatterChart ---- */}
  
  .scatterChartDot {
    stroke: ${colors.midnightLight};
    stroke-width: 1px;
  }
  
  .dots-labels-small {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  
  
  {/* ---- BarChart ---- */}
  
  .bars-axis {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    font-style: normal;
  }
  
  .small-bars-labels { 
    font-size: 8px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .medium-bars-labels { 
    font-size: 10px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }  
  
  .bars-labels { 
    font-size: 12px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .large-bars-labels { 
    font-size: 14px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } 
  
  .large-and-bold-bars-labels { 
    font-size: 14px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .pie-bars-labels { 
    font-size: 14px;
    font-family: ${FontFamilyEnum.OPEN_SANS};
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } 
  
  {/* ---- MapChart ---- */}

  .mapboxgl-ctrl {
    display: none !important;
  }
  
  .sphere {
    fill: ${colors.offWhite};
  }
  
  .country{
    /* fill:lightgreen; */
    stroke: ${colors.darkGrey};
    stroke-width: 1;
    stroke-opacity: 0.5;
  }
  .tooltips{
    position: absolute;
    background-color: #fff;
    opacity: .9;
    width:auto;
    text-align: center;
    height: auto;
  }
  .tooltips ::after{
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left:-15px;
    border-width:10px;
    border-style: solid;
    border-color: white transparent transparent  transparent;
  }
  
  .mapbox-marker {
    display: block;
    border: 1px solid white;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
  }
  
  .mapbox-marker-label {
    font-family: ${FontFamilyEnum.OPEN_SANS};
    margin-top: -25px;
    display: block;
    cursor: pointer;
    padding: 0;
  }
  .no-scroller-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  .no-scroller-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  
  {/* ---- InteractiveDonutPieChart ---- */}
  
  path.slice{
    stroke-width:2px;
  }

  polyline{
    opacity: .3;
    stroke: black;
    stroke-width: 2px;
    fill: none;
  }

  ${OpenSans}
  ${GeoSlab}
  
  :root{
    --radio-button-margin: 3rem;  
  }
`;

export default GlobalStyle;
