/*
Author: Eli Elad Elrom
Component: src/utils/LayoutHelper.tsx
*/

import {
  BottomMainWidgetsTypeEnum,
  MainWidgetsTypeEnum,
  RightWidgetsTypeEnum,
  SelectedMainHeaderTypeEnum,
} from '../components/pages/DashboardPage/WidgetsType';
import { UserModel } from '../models';
import logger from './logger';

export const TOP_HEADER_HEIGHT = 125;
export const RIGHT_SIDE_FILTERS_FIXED_WIDTH = 248;
export const COLLAPSE_BOTTOM_HEIGHT = 34;
export const MARKET_INTELLIGENT_HEIGHT = 210;
export const MIN_X_SCATTER_CHART_LEFT = 150;
export const CRITICAL_DATE_HEADER = '12rem';
export const HEATMAP_TOP_GAP = 168;

// Headers
export const SITE_HEADER_HEIGHT = '6.2rem';
export const DASHBOARD_HEADER_HEIGHT = '5rem';
export const HEADER_WIDGET = 40;

export const DASHBOARD_HEADER_HEIGHT_IN_PIXELS = 219;
export const SECOND_TOP_HEADER_HEIGHT = 215;
export const MOR_HEADER_HEIGHT = 280;
export const METRICS_HEADER_HEIGHT = 200;

export const getDashboardMetricsHeaderHeightByDashboard = (
  userModel: UserModel,
  widgetType?: string | null
) => {
  let metricsHeaderHeight = TOP_HEADER_HEIGHT;
  if (
    userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainHeader !==
    SelectedMainHeaderTypeEnum.NONE
  ) {
    switch (userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainHeader) {
      case SelectedMainHeaderTypeEnum.MOR_HEADER:
        if (widgetType === RightWidgetsTypeEnum.MARKET_TO_MARKET_BAR_CHART)
          metricsHeaderHeight = MOR_HEADER_HEIGHT - 45;
        else {
          metricsHeaderHeight = MOR_HEADER_HEIGHT;
        }
        break;
      case SelectedMainHeaderTypeEnum.METRICS_HEADER:
        metricsHeaderHeight = METRICS_HEADER_HEIGHT;
        break;
      case SelectedMainHeaderTypeEnum.LEASE_METRICS_HEADER:
        metricsHeaderHeight = MARKET_INTELLIGENT_HEIGHT;
        break;
      case null:
        metricsHeaderHeight = 0;
        break;
    }
  }
  return metricsHeaderHeight;
};

export const getRightWidgetWidth = (userModel: UserModel, width: number) => {
  let rightWidgetWidth = 0;
  if (
    userModel.selectedClientDashboardModel?.dashboardModel?.rightWidget !==
    RightWidgetsTypeEnum.NONE
  ) {
    switch (userModel.selectedClientDashboardModel?.dashboardModel?.rightWidget) {
      case RightWidgetsTypeEnum.PORTFOLIO_TABLE:
        rightWidgetWidth = 500;
        break;
      case RightWidgetsTypeEnum.PORTFOLIO_OVERVIEW_BAR_CHART:
        rightWidgetWidth = 500;
        break;
      case RightWidgetsTypeEnum.MAP_BOX_SCATTER:
        const calculated = (width * 20) / 100;
        rightWidgetWidth = calculated;
        break;
      case RightWidgetsTypeEnum.METRIC_OUTLIER_ANALYSIS_BARS:
        rightWidgetWidth = 500;
        break;
      case RightWidgetsTypeEnum.MARKET_TO_MARKET_BAR_CHART:
        if (isNaN(width)) {
          logger('LayoutHelper Error: unable to get width');
        } else {
          const calcWidthByPercentage = (width * 35) / 100;
          rightWidgetWidth = calcWidthByPercentage;
        }
        break;
    }
  }
  return rightWidgetWidth;
};

export const getRightWidgetCollapseWidth = (userModel: UserModel) => {
  let width = 50;
  if (
    userModel.selectedClientDashboardModel?.dashboardModel?.rightWidget !==
    RightWidgetsTypeEnum.NONE
  ) {
    switch (userModel.selectedClientDashboardModel?.dashboardModel?.rightWidget) {
      case RightWidgetsTypeEnum.PORTFOLIO_TABLE:
        width = 46;
        break;
      case RightWidgetsTypeEnum.PORTFOLIO_OVERVIEW_BAR_CHART:
        width = 40;
        break;
      case RightWidgetsTypeEnum.MARKET_TO_MARKET_BAR_CHART:
        width = 40;
        break;
    }
  }
  return width;
};

// -------------------------------------- Bottom Widget ----------------------------------------------

export const getBottomWidgetHeight = (userModel: UserModel, isBottomCollapse?: boolean) => {
  const selectedDashboard = userModel.selectedClientDashboardModel?.dashboardModel;
  let bottomWidgetHeight = 0;
  if (selectedDashboard?.selectedMainBottomType !== BottomMainWidgetsTypeEnum.NONE) {
    switch (selectedDashboard?.selectedMainBottomType) {
      case BottomMainWidgetsTypeEnum.LEASE_REMAINING_MONTHS_TO_EXPIRATION:
        bottomWidgetHeight = isBottomCollapse ? COLLAPSE_BOTTOM_HEIGHT : 200;
        break;
      case BottomMainWidgetsTypeEnum.BOTTOM_PROPERTY_COST_PARETO:
        bottomWidgetHeight = isBottomCollapse ? COLLAPSE_BOTTOM_HEIGHT - 88 : 200;
        break;
    }
  } else {
    if (selectedDashboard?.rightWidget === RightWidgetsTypeEnum.MAP_BOX_SCATTER) {
      bottomWidgetHeight = -4;
    }
  }
  return bottomWidgetHeight;
};

export const hasBottomWidget = (userModel: UserModel) =>
  userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainBottomType !==
  BottomMainWidgetsTypeEnum.NONE;

export const getMapTooltipTopPaddingBasedOnHeader = (userModel: UserModel) => {
  let retPadding = 200;
  if (
    userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainHeader ===
    SelectedMainHeaderTypeEnum.NONE
  ) {
    retPadding = 150;
  }
  return retPadding;
};

export const getShowBottomBorder = (selectedMainWidget?: string | null) => {
  const retVal = selectedMainWidget === MainWidgetsTypeEnum.LEASE_EXPIRATIONS_DASHBOARD;
  return retVal;
};

// -------------------------------------- Main Widget ----------------------------------------------

export const getMainWidgetsHeight = (
  screenHeight: number,
  userModel: UserModel,
  headerHeight?: number | undefined,
  isBottomCollapse?: boolean
) => {
  const header = headerHeight !== undefined ? headerHeight : SECOND_TOP_HEADER_HEIGHT;
  const bottomHeight = getBottomWidgetHeight(userModel, isBottomCollapse);
  return screenHeight - header - bottomHeight;
};

export const additionalPaddingByWidgetType = (widgetType: string) => {
  let retVal = 0;
  switch (widgetType) {
    case RightWidgetsTypeEnum.PORTFOLIO_OVERVIEW_BAR_CHART:
      retVal = 7;
      break;
    case MainWidgetsTypeEnum.SCATTER_REMAINING_MONTHS_TO_EXPIRATION:
      retVal = 15;
      break;
    case MainWidgetsTypeEnum.MAP_BOX:
      retVal = 90;
      break;
  }
  return retVal;
};

export const getMainWidgetWidth = (
  screenWidth: number,
  userModel: UserModel,
  filterCollapse?: boolean,
  isRightWidgetCollapse?: boolean
) => {
  const filterCollapseWidth = filterCollapse ? 0 : RIGHT_SIDE_FILTERS_FIXED_WIDTH;
  const rightWidgetWidth = isRightWidgetCollapse
    ? getRightWidgetCollapseWidth(userModel)
    : getRightWidgetWidth(userModel, screenWidth);
  return screenWidth - filterCollapseWidth - rightWidgetWidth;
};

export const getMainBottomWidgetWidth = (screenWidth: number, userModel: UserModel) => {
  let retWidth = screenWidth;
  const selectedMainBottomType =
    userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainBottomType;
  // const rightWidgetWidth = getRightWidgetWidth(userModel, screenWidth);
  switch (
    selectedMainBottomType
    // Example of use case
    // case BottomMainWidgetsTypeEnum.SINGLE_STACKED_BAR:
    //   retWidth = screenWidth - RIGHT_SIDE_FILTERS_FIXED_WIDTH - rightWidgetWidth;
  ) {
  }
  return retWidth;
};
