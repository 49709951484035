import * as React from 'react';
import { VariantType } from 'notistack';
import { StyledClose } from './Toaster.css';

function ToasterCloseButton({ onClick, variant }: ToasterCloseButtonProps) {
  return <StyledClose onClick={onClick} color={variant} />;
}

export interface ToasterCloseButtonProps {
  onClick: () => void;
  variant: VariantType;
}

export default ToasterCloseButton;
